<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1" data-uk-grid>
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3">
              <span data-uk-icon="icon: copy;ratio: 1.5"></span> Seiten
            </h3>
          </div>
          <div>
            <router-link :to="{name: 'pages-edit'}" class="uk-button uk-button-primary">
              <span uk-icon="plus-circle"></span>
              Seite erstellen
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid" data-uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small uk-margin-large-bottom">
          <div class="uk-card-body">
            <div class="uk-grid uk-grid-small uk-flex uk-flex-middle">
              <div class="uk-width-auto">
                <form class="uk-search uk-search-default uk-width-medium">
                  <span uk-search-icon></span>
                  <input class="uk-search-input" placeholder="Suchen..." v-model="search" @keydown.enter.prevent="searchPage">
              </form>
              <a href="#" class="uk-icon uk-margin-small-left" data-uk-icon="refresh" @click.prevent="resetSearch"></a>
              </div>
              <div class="uk-width-expand" v-if="meta && meta.pagination">
                <pagination :meta="meta" v-if="meta.pagination.total_pages > 1" class="uk-float-right"></pagination>
              </div>
            </div>
            <hr>
            <div class="uk-flex uk-flex-betwwen">
              <div class="title uk-text-uppercase uk-text-muted uk-text-small uk-width-expand">Titel</div>
              <div class="title uk-text-uppercase uk-text-muted uk-text-small uk-width-large">URL</div>
               <div class="title uk-text-uppercase uk-text-muted uk-text-small uk-text-center">Status</div>
                <div class="title uk-text-uppercase uk-text-muted uk-text-small" style="width: 80px">Geändert</div>
                <div class="title uk-text-uppercase uk-text-muted uk-text-small" style="width: 80px"></div>
            </div>
            <Tree
              :data="list"
              draggable="draggable"
              :cross-tree="true"
              :get-trigger-el="getTriggerEl"
              @change="treeChange">
              <div slot-scope="{data, store}" v-if="!data.isDragPlaceHolder">
                  <div class="uk-flex uk-flex-between uk-visible-toggle">
                    <div class="uk-flex-middle uk-width-expand uk-width-expand">
                      <span class="drag-trigger uk-invisible-hover" uk-icon="icon: grid"></span>
                      <span v-if="data.children && data.children.length" @click="store.toggleOpen(data)">
                        <span uk-icon="chevron-down" v-if="data.open"></span>
                        <span uk-icon="chevron-right" v-else></span>
                      </span>
                      <span class="uk-margin-small-left">
                          <router-link
                            :to="{name: 'pages-edit', params: {'id': data.id }}">{{ data.title }}
                        </router-link>
                      </span>
                    </div>
                    <div class="uk-width-large uk-margin-small-right">
                      {{ data.slug }}
                    </div>
                    <div class="uk-text-center" style="width: 80px">
                      <span v-if="data.active" uk-icon="check" class="uk-text-success uk-icon"></span>
                      <span v-else uk-icon="ban" class="uk-text-danger uk-icon"></span>
                    </div>
                    <div class="uk-width-small uk-text-right uk-margin-small-right">
                      {{ data.updatedAt }}
                    </div>
                    <div class="uk-text-right uk-margin-small-right"  style="width: 80px">
                      <a href="#" class="uk-icon-link" uk-icon="more"></a>
                      <div uk-dropdown="mode: click;animation: uk-animation-slide-top-small;offset: 5;pos:bottom-right">
                        <ul class="uk-nav uk-dropdown-nav  uk-text-left">
                          <li>
                        <router-link
                          :to="{name: 'pages-edit', params: {'id': data.id }}"
                          >
                          Bearbeiten
                        </router-link>
                      </li>
                      <li>
                        <a class="uk-text-danger uk-icon" href="#"
                          @click.prevent="remove(data.id)">Löschen</a>
                      </li>
                        </ul>
                      </div>
                    </div>
                  </div>
              </div>
            </Tree>
            <hr>
            <div class="uk-width-expand" v-if="meta && meta.pagination">
                <pagination :meta="meta" v-if="meta.pagination.total_pages > 1" class="uk-float-right"></pagination>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageService from '@/services/page.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import { DraggableTree } from 'vue-draggable-nested-tree'
import { mapGetters, mapActions } from 'vuex'
import Pagination from '@/components/Pagination'
export default {
  components: {
    Tree: DraggableTree,
    Pagination
  },
  data () {
    return {
      search: ''
    }
  },
  mounted () {
    this.$store.commit('SET_LAYOUT', 'default-layout')
    this.getPageList({ ...this.$route.query })
  },

  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    list () {
      return this.pages.data
    },
    meta () {
      return this.pages.meta
    }
  },

  watch: {
    '$route.query': 'getPageList'
  },

  methods: {
    ...mapActions({
      getPageList: 'pages/getPageList'
    }),
    getTriggerEl (nodeVm) {
      return nodeVm.$el.querySelector('.drag-trigger')
    },
    treeChange (node, targetTree) {
      PageService.reorder(targetTree.getPureData()).then(response => {
        this.$store.dispatch('pages/getPageList')
      })
    },
    remove (section) {
      const $this = this
      this.$uikit.modal.confirm('<strong>Seite wirklich löschen?</strong><p class="uk-text-danger">Achtung: Es werden alle darin enthaltenen <strong>Bereiche</strong> und <strong>Widgets</strong> gelöscht!</p>', {
        labels: {
          cancel: 'Abrechen',
          ok: 'Löschen'
        }
      }).then(function () {
        PageService.delete(section).then(response => {
          $this.getPageList()
          iziToast.success({
            title: 'OK',
            message: 'Seite gelöscht!',
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft'
          })
        })
      }, function () {
      //
      })
    },
    searchPage () {
      this.getPageList(Object.assign({}, { q: this.search }))
    },
    resetSearch () {
      this.search = ''
      this.getPageList()
    }
  }
}
</script>
<style lang="scss">
  .title {
    padding: 16px 26px;
  }
  .drag-trigger {
    cursor: all-scroll;
  }
  .tree-node-inner{
    padding: 16px 0;
  }
  .tree-node-inner-back {
    border-top: 1px solid #e5e5e5;
  }

.tree-node.open {
  > .tree-node-children {
    padding-left: 10px;
  }
}

  .draggable-placeholder{
  }
  .dragging {
     background: rgba(0, 136, 249, 0.09);
     border: 1px solid #e5e5e5;
  }
  .draggable-placeholder-inner{
    border: 1px dashed #0088F8;
    box-sizing: border-box;
    background: rgba(0, 136, 249, 0.09);
    color: #0088f9;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .tree-node-inner-back {
    margin-bottom: 0 !important;
  }
</style>
